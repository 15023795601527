<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.header.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Rekanan</label>
        <vs-input class="w-full" :value="initData.header.nama_rekanan" readonly/>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Jenis PO</label>
        <vs-input class="w-full" :value="initData.header.jenis_po" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">No. PO</label>
        <vs-input class="w-full" :value="initData.header.no_po" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Tgl PO</label>
        <vs-input class="w-full" :value="initData.header.tgl_po" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.header.keterangan" readonly/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabHeader',
  props: {
    initData: { default: null, type: Object }
  },
  methods: {
    next () {
      const currentActiveTab = this.$store.state.approvals.poKontrakApproval.activeTab
      this.$store.commit('approvals/poKontrakApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
